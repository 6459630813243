import {createWebHistory, createRouter} from "vue-router";
import store from "../store/index.js";

export const routes = [

  {
    path: '/',
    component: () => import('../layouts/AuthLayout.vue'),
    children: [
      {
        path: "",
        name: 'login',
        meta: {
          title: 'Connexion',
        },
        component: () => import('../components/users/Login.vue')
      },
      {
        path: 'create-password/:token',
        name: 'create-password',
        meta: {
          title: 'Créer mot de passe',
        },
        component: () => import('../components/users/CreatePassword.vue')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        meta: {
          title: 'Mot de passe oublié',
        },
        component: () => import('../components/users/ForgotPassword.vue')
      },
      {
        path: '404',
        name: 'PageNotExist',
        meta: {
          title: 'Oups 404',
        },
        component: () => import('../pages/404.vue'),
      },
    ]
  },

  {
  path: '/personnel',
  name: 'personnelLayout',
  component: () => import('../layouts/AppLayout.vue'),
  children:
    [
      {
        path: '',
        name: 'personnelIndex',
        meta: {
          title: 'Personnel',
        },
        component: () => import('../pages/users/index.vue'),
      },
      {
        path: 'ajouter',
        name: 'personnelAdd',
        meta: {
          title: 'Ajouter du personnel',
        },
        component: () => import('../pages/users/Add.vue'),
      },
      {
        path: 'editer/:id?',
        name: 'personnelEdit',
        meta: {
          title: 'Éditer du personnel',
          edit: true
        },
        component: () => import('../pages/users/Edit.vue'),
      }
    ]
  },


  {
  path: '/observations',
  name: 'observationLayout',
  component: () => import('../layouts/AppLayout.vue'),
  children:
    [
      {
        path: '',
        name: 'observationIndex',
        meta: {
          title: 'Observations',
        },
        component: () => import('../pages/observations/index.vue'),
      },
      {
        path: 'ajouter',
        name: 'observationsAdd',
        meta: {
          title: 'Ajouter une observation',
        },
        component: () => import('../pages/observations/Add.vue'),
      },
      {
        path: 'editer/:id?',
        name: 'observationsEdit',
        meta: {
          title: 'Éditer votre observation',
          edit: true
        },
        component: () => import('../pages/observations/Edit.vue'),
      }
    ]
  },

  {
    path: '/forum',
    name: 'forumLayout',
    component: () => import('../layouts/AppLayout.vue'),
    children:
      [
        {
          path: '',
          name: 'forumIndex',
          meta: {
            title: 'Forum',
          },
          component: () => import('../pages/forum/index.vue'),
        },
        {
          path: 'ajouter',
          name: 'forumPostAdd',
          meta: {
            title: 'Ajouter une note',
          },
          component: () => import('../pages/forum/Add.vue'),
        },
        {
          path: 'editer/:id?',
          name: 'forumPostEdit',
          meta: {
            title: 'Éditer votre note',
            edit: true
          },
          component: () => import('../pages/forum/Edit.vue'),
        }
      ]
    },

  {
  path: '/gardes',
  name: 'gardesLayout',
  component: () => import('../layouts/AppLayout.vue'),
  children:
    [
      {
        path: '',
        name: 'gardesIndex',
        meta: {
          title: 'Gardes',
        },
        component: () => import('../pages/gardes/index.vue'),
      },
      {
        path: 'ajouter',
        name: 'gardeAdd',
        meta: {
          title: 'Ajouter une garde',
          edit: true
        },
        component: () => import('../pages/gardes/Add.vue'),
      },
      {
        path: 'editer/:id?',
        name: 'gardeEdit',
        meta: {
          title: 'Éditer une garde',
          edit: true
        },
        component: () => import('../pages/gardes/Edit.vue'),
      }
    ]
  },

  {
  path: '/enfants',
  name: 'childsLayout',
  component: () => import('../layouts/AppLayout.vue'),
  children:
    [
      {
        path: '',
        name: 'childsIndex',
        meta: {
          title: 'Enfants',
        },
        component: () => import('../pages/childs/index.vue'),
      },
      {
        path: 'ajouter',
        name: 'childsAdd',
        meta: {
          title: 'Ajouter un enfant',
        },
        component: () => import('../pages/childs/Add.vue'),
      },
      {
        path: 'editer/:child_id?',
        name: 'childsEdit',
        meta: {
          title: 'Éditer un enfant',
          edit: true
        },
        component: () => import('../pages/childs/Edit.vue'),
      }
    ]
  },


  {
  path: '/documents',
  name: 'fileLayout',
  component: () => import('../layouts/AppLayout.vue'),
  children:
    [
      {
        path: '',
        name: 'fileIndex',
        meta: {
          title: 'Documents',
        },
        component: () => import('../pages/files/index.vue'),
      },
      {
        path: 'enfant/:child_id',
        name: 'fileChildIndex',
        meta: {
          title: 'Documents',
        },
        component: () => import('../pages/files/index.vue'),
      },
      {
        path: 'personnel/:user_id',
        name: 'fileUsersIndex',
        meta: {
          title: 'Documents',
        },
        component: () => import('../pages/files/index.vue'),
      },
      {
        path: 'ajouter',
        name: 'fileAdd',
        meta: {
          title: 'Ajouter un document',
        },
        component: () => import('../pages/files/Add.vue'),
      },
      {
        path: 'editer/:id',
        name: 'fileEdit',
        meta: {
          title: 'Éditer un document',
          edit: true
        },
        component: () => import('../pages/files/Edit.vue'),
      }
    ]
  },

  {
    path: '/medicale',
    name: 'medicaleLayout',
    component: () => import('../layouts/AppLayout.vue'),
    children:
      [
        {
          path: '',
          name: 'medicaleIndex',
          meta: {
            title: 'Fiches Médicales',
          },
          component: () => import('../pages/medicale/index.vue'),
        },
        {
          path: 'ajouter',
          name: 'medicaleAdd',
          meta: {
            title: 'Ajouter une Fiche Médicale',
          },
          component: () => import('../pages/medicale/Add.vue'),
        },
        {
          path: 'editer/:id',
          name: 'medicaleEdit',
          meta: {
            title: 'Éditer une Fiche Médicale',
            edit: true
          },
          component: () => import('../pages/medicale/Edit.vue'),
        }
      ]
    },

  {
    path: '/valves',
    name: 'valvesLayout',
    component: () => import('../layouts/AppLayout.vue'),
    children:
      [
        {
          path: '',
          name: 'valvesIndex',
          meta: {
            title: 'Valves',
          },
          component: () => import('../pages/valves/index.vue'),
        },
        {
          path: 'ajouter',
          name: 'valvesAdd',
          meta: {
            title: 'Ajouter valves',
          },
          component: () => import('../pages/valves/Add.vue'),
        },
        {
          path: 'editer/:id',
          name: 'valvesEdit',
          meta: {
            title: 'Éditer valve',
            edit: true
          },
          component: () => import('../pages/valves/Edit.vue'),
        }
      ]
    },

    {
      path: '/pv',
      name: 'pvLayout',
      component: () => import('../layouts/AppLayout.vue'),
      children:
        [
          {
            path: '',
            name: 'pvIndex',
            meta: {
              title: 'PV de réunions',
            },
            component: () => import('../pages/pv/index.vue'),
          },
          {
            path: 'ajouter',
            name: 'pvAdd',
            meta: {
              title: 'Ajouter PV de réunions',
            },
            component: () => import('../pages/pv/Add.vue'),
          },
          {
            path: 'editer/:id',
            name: 'pvEdit',
            meta: {
              title: 'Éditer PV de réunions',
              edit: true
            },
            component: () => import('../pages/pv/Edit.vue'),
          }
        ]
      },

      {
        path: '/heures',
        name: 'overtimeLayout',
        component: () => import('../layouts/AppLayout.vue'),
        children:
          [
            {
              path: '',
              name: 'overtimeIndex',
              meta: {
                title: 'Heures supplémentaires',
              },
              component: () => import('../pages/overtime/index.vue'),
            },
            {
              path: 'ajouter',
              name: 'overtimeAdd',
              meta: {
                title: 'Ajouter des heures supplémentaires',
              },
              component: () => import('../pages/overtime/Add.vue'),
            },
            {
              path: 'editer/:id',
              name: 'overtimeEdit',
              meta: {
                title: 'Éditer ses heures supplémentaires',
                edit: true
              },
              component: () => import('../pages/overtime/Edit.vue'),
            }
          ]
        },

  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  }

    // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('../layouts/AppLayout'),
  //   children: [
  //     {
  //       path: "",
  //       meta: {
  //         title: 'Dashboard',
  //       },
  //       component: () => import('../pages/Dashboard'),
  //     }
  //   ]
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' | ' + store.state.entity.charAt(0).toUpperCase() + store.state.entity.slice(1);

  next()
})

export default router;
